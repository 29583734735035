@use 'src/styles/style' as *;
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: $common-container-padding;
  height: 100%;
}
.contentWrapper {
  flex-grow: 1;
}
