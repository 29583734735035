@use '/src/styles/style.scss' as *;

.welcomeBackModalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 10px 0;
  text-align: center;
  font-weight: $fw-extrabold;
  font-size: 20px;
}

.notificationContent {
  flex-grow: 1;
  padding-bottom: 15px;
  width: 100%;
  max-height: 50vh;

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile-mobileMedium {
    margin-top: 0;
  }
}
.employeesNotification {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.profitWrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  @include mobile-mobileMedium {
    gap: 3px;
  }
}
.profitCount {
  font-weight: $fw-bold;
  font-size: 26px;

  @include mobile-mobileMedium {
    font-size: 17px;
  }
}
.coinIcon {
  width: 25px;
  height: 25px;

  @include mobile-mobileMedium {
    width: 17px;
    height: 17px;
  }
}

.employeesParamsWrapper,
.infoWrapper {
  display: flex;
  align-items: center;
  position: relative;

  font-size: 12px;
  font-weight: $fw-bold;

  @include mobile-mobileMedium {
    font-size: 10px;
  }
}
.infoWrapper {
  margin-top: 3px;
  margin-right: 5px;
}
.employeesInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: $color-white;
}
.textGold {
  color: $color-orange-yellow;
}
.icon,
.iconClock {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
.iconClock {
  width: 14px;
  height: 14px;

  svg {
    path,
    circle {
      stroke: $color-black-light;
    }
  }
}

.minus {
  padding-top: 3px;
  width: 45px;
  height: 45px;
  border: none;
  background-color: transparent;

  @include mobile-mobileMedium {
    width: 45px;
    height: 45px;
  }
}
.marker {
  width: 17px;
  height: 16px;
  position: absolute;
  user-select: none;
  top: -6px;
  right: -7px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.button {
  font-weight: 600;
  &.start {
    display: flex;
    gap: 3px;
  }
  &.working {
    display: flex;
    gap: 3px;
    background: #43736c;
  }
  &.takeIncome {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    font-size: 12px;
    .revenue {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
.hireButtonContent {
  display: flex;
  align-items: center;
  gap: 10px;
}
.priceWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.iconWrapper {
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}
.textGold {
  font-weight: $fw-bold;
  color: $color-orange-yellow;
}

.coinsRef {
  position: relative;
  width: 100%;
}

.actionBtnWrapper {
  flex-grow: 1;
  width: 100%;
}
