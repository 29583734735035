@use '/src/styles/style.scss' as *;

.commonButton {
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow:
    inset 0 5px 5px 0 rgba(255, 153, 0, 0.35),
    inset 0 -5px 10px 0 rgba(59, 166, 157, 0.75),
    10px 10px 20px 0 rgba(0, 0, 0, 0.3);

  font-size: 17px;
  font-weight: $fw-bold;
  color: $color-white;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile-mobileMedium {
    padding: 7px 0;
    font-size: 14px;
  }

  &:disabled {
    filter: grayscale(100%);
  }
}

.Blue {
  background: #037bc8;
  @include shineAnim;
}

.Green {
  background: $color-green
    linear-gradient(0deg, rgba(129, 200, 0, 1) 0%, rgba(174, 171, 0, 0.499124649859944) 40%, rgba(129, 200, 0, 1) 100%);
  @include shineAnim;
}

.Red {
  background: $color-red
    linear-gradient(
      0deg,
      rgba(210, 210, 210, 0.19940476190476186) 0%,
      rgba(220, 14, 44, 1) 50%,
      rgba(105, 105, 105, 0.048144257703081283) 100%
    );
}

.Disabled {
  pointer-events: none;
  box-shadow: none;
  color: $color-blue-black;
  background: $color-white linear-gradient(0deg, rgba(41, 105, 141, 0.15) 0%, rgba(89, 184, 238, 0.15) 100%);
}

.White {
  color: $color-blue;
  box-shadow: inset 0 3px 5px 0 #f36c3f;
  background: $color-black-gradient;
}
