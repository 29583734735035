.wrapper {
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
    padding: 0px 30px;
    font-size: 20px;
  }
}
