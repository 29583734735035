$color-white: #fff;
$color-black: #000000;
$color-black-light: #4d4d4d;
$color-black-lighter: #686868;
$color-blue: #3495d3;
$color-blue-black: #205aee;
$color-green: #81c800;
$color-orange-yellow: #d3b600;
$color-red: #dc0e2c;
$color-orange: #db6429;
$color-yellow: #f2d000;
$color-light-blue-bg: rgba(191, 245, 248, 0.8);
$color-black-light: #6d6d6d;
$color-body-bg: #1f1e26;
$color-black-gray: #423d58;
$color-black-gradient: linear-gradient(180deg, #353341 0%, #292830 100%);

$color-black-active: #1b1b1b;
$color-black-main: #2e2e30;
$color-black-light: #6d6d6d;
$color-black-lighter: #767a7b;
$color-gray: #a4abaa;
$color-gray-light: #c7d0cf;
$color-gray-lighter: #d9d9d9;
$color-gray-lighter-wow: #f3f5f5;
$color-gray-bg: #f9f9f9;
$color-red-active: #cb2b00;
$color-red-hover: #ff5527;
$color-red-main: #ff3600;
$color-orange: #ff6b00;
$color-yellow: #ffd600;
$color-emerald: #4bcf95;
$color-green: #35ce1c;
$color-blue-active: #007ec4;
$color-blue-hover: #49beff;
$color-blue: #18a4f2;
$color-office-blue: #0b81cd;
$color-blue-bg: #e4f0f2;
