@use 'style' as *;

:root {
  --btn-border: 1px solid rgba(255, 255, 255, 0.2);
  --btn-bg: transparent; // #333
  --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 0.5);
  --btn-text-color: #f4f4f4;

  --shine-degree: 120deg;
  --shine-color: rgba(255, 255, 255, 0.7);
  --shine-effect: linear-gradient(var(--shine-degree), transparent, var(--shine-color), transparent);
  --shine-transition: all #{$anim-speed}s ease-in-out;
}

html {
  box-sizing: border-box;

  & *,
  *::before,
  *::after {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    font-family: var(--font-motserrat);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  color: $color-black;
}

body {
  -webkit-overflow-scrolling: touch;
}

body:has(#modal__root div),
body:has(reach-portal) {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

button {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  color: $color-black;
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  width: 100%;
  pointer-events: none;
}

#root {
  touch-action: none !important;
  overflow: hidden !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
