@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  pointer-events: none;
  max-width: 430px;
  transform: translateX(-50%);

  position: absolute;
  z-index: 100;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
}
.text {
  max-width: 420px;
  word-wrap: break-word;
  opacity: 0;
  text-align: center;

  position: absolute;
  z-index: 10;

  font-size: 25px;
  font-weight: $fw-bold;
  color: yellow;
  text-shadow:
    -0 -1px 2px #000000,
    0 -1px 2px #000000,
    -0 1px 2px #000000,
    0 1px 2px #000000,
    -1px -0 2px #000000,
    1px -0 2px #000000,
    -1px 0 2px #000000,
    1px 0 2px #000000,
    -1px -1px 2px #000000,
    1px -1px 2px #000000,
    -1px 1px 2px #000000,
    1px 1px 2px #000000,
    -1px -1px 2px #000000,
    1px -1px 2px #000000,
    -1px 1px 2px #000000,
    1px 1px 2px #000000;
}

.textVisible {
  animation: moveUp 1.5s ease-out forwards;
  -webkit-tap-highlight-color: transparent;
}

@keyframes moveUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    transform: translateY(-200px);
  }
}
