@use '/src/styles/style.scss' as *;

.countContainer {
  width: fit-content;
  gap: 10px;
  border-radius: 80px;
  background: linear-gradient(180deg, rgba(231, 190, 175, 0.25) 0%, rgba(253, 240, 226, 0.25) 100%);
  box-shadow:
    inset 0 -3px 3px 0 rgba(155, 141, 227, 1),
    inset 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid $color-white;

  display: flex;
  align-items: center;

  font-size: 35px;
  line-height: 29px;
  color: $color-black;
}
