@use 'src/styles/style' as *;

.mainWrapper {
  width: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coinText {
  font-size: 17px;
  color: $color-white;
  font-weight: $fw-bold;

  @include mobile-mobileMedium {
    font-size: 14px;
  }
}
.coinIcon {
  width: 25px;
  height: 25px;
  margin-left: 3px;

  @include mobile-mobileMedium {
    width: 17px;
    height: 17px;
  }
}
.price {
  padding: 3px 0;
  color: $color-orange-yellow;
  font-size: 12px;
  font-weight: $fw-bold;

  display: flex;
  gap: 2px;
}

.marker {
  width: 17px;
  height: 16px;
  position: absolute;
  top: 1px;
  right: 1px;
}
