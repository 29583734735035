@use 'src/styles/style' as *;

.cardWrapper {
  padding: 5px;
  width: 100%;
}

.cardInfoWrapper {
  width: 100%;
  display: flex;
  padding: 5px 0 0;
}

.hired {
  border: 1px solid #7f77a5;
  border-radius: 5px;
  width: 100%;
  height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: $fw-bold;
  color: #7f77a5;

  display: flex;
  align-items: center;
  justify-content: center;
}

// user name info
.userNameWrapper {
  display: flex;
  align-items: center;

  border-right: 2px solid #7f77a5;
  max-width: 40%;
  width: 100%;
}

.userName {
  padding-left: 5px;
  text-align: start;
  font-size: 12px;
  font-weight: $fw-bold;
  word-break: break-word;
  width: 100%;
}

// user skills info
.userSkills {
  flex-grow: 1;
  text-align: start;
  padding: 0 5px;
  border-right: 2px solid #7f77a5;
}

.skill {
  font-weight: $fw-bold;
  font-size: 13px;

  display: flex;
  align-items: center;
  gap: 3px;

  span {
    color: #7f77a5;
  }
}

.powerCounter {
  font-weight: $fw-bold;
  background: linear-gradient(90deg, rgba(255, 133, 65, 1) 0%, rgba(255, 110, 43, 1) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info {
  color: $color-orange-yellow;

  span {
    color: $color-white;
  }
}
// buttons
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: content-box;
  padding-left: 5px;
}

// sell info
.sellInfo {
  width: 90px;
  height: 50px;
  padding: 10px 0;
  font-size: 16px;
  line-height: 0.8;
  font-weight: $fw-semibold;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sellTitle {
  margin-bottom: 5px;
  color: #7f77a5;
}
.sellCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: $color-orange-yellow;
}
// hire button
.button {
  width: 90px;
  height: 50px;
  padding: 10px 0;
  border-radius: 10px;
  background: #037bc8;
  box-shadow:
    inset 0 5px 5px 0 rgba(255, 153, 0, 0.35),
    inset 0 -5px 10px 0 rgba(59, 166, 157, 0.75),
    10px 10px 20px 0 rgba(0, 0, 0, 0.3);

  font-size: 16px;
  font-weight: $fw-medium;

  display: flex;
  justify-content: center;
  align-items: center;

  @include shineAnim;
}

.blue {
  color: $color-white;
  background: #037bc8;
  box-shadow:
    inset 0 5px 5px 0 rgba(255, 153, 0, 0.35),
    inset 0 -5px 10px 0 rgba(59, 166, 157, 0.75),
    10px 10px 20px 0 rgba(0, 0, 0, 0.3);
}
.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #1da1f5;
  background: transparent;
}

.hireButtonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.priceWrapper {
  display: flex;
  align-items: center;
}
.coinIcon {
  width: 17px;
  height: 17px;
  margin-right: 3px;
}
.text {
  font-size: 17px;
  color: $color-black;
  font-weight: $fw-bold;

  @include mobile-mobileMedium {
    font-size: 14px;
  }
}
.textRed {
  color: $color-red;
}

.textGold {
  color: $color-orange-yellow;
}
