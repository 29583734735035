.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}
.buttonWrapper {
  width: 100%;
  display: flex;
  gap: 5px;
}
