@use '/src/styles/style.scss' as *;

.energyLimit {
  background-color: #f2d000;
  border-radius: 150px;
  padding: 4px 10px;
  position: relative;
  z-index: 2;

  font-weight: $fw-bold;
  font-size: 14px;
  color: #b65310;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: 0.01px solid $color-white;
  outline: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow:
    5px 5px 16px -11px rgba(79, 70, 67, 0.6) inset,
    -7px -7px 5px -5px rgba(245, 242, 241, 0.6) inset;
}

.iconWrapper {
  width: 20px;
  height: 20px;
}
