.wrapper {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.modalTitle {
  margin-bottom: 10px;
  text-align: center;
}
