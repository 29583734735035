@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: $common-container-padding;
}

.pageTitle {
  width: 100%;
  text-align: center;
  padding: 3px 15px 3px 5px;
  gap: 10px;
  border-radius: 80px;

  border: 1px solid $color-white;
  box-shadow:
    inset -7px -7px 8px 0 rgba(255, 255, 255, 0.35),
    inset 7px 7px 8px 0 rgba(0, 0, 0, 0.15);

  font-size: 20px;
  font-weight: $fw-bold;
  color: $color-black;

  @include mobile-mobileMedium {
    font-size: 17px;
  }
}

.cardsWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
}
