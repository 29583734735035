@use 'src/styles/style' as *;

.button {
  height: 55px;
  font-size: 20px;
  font-weight: $fw-medium;
  text-transform: uppercase;

  @include mobile-mobileMedium {
    height: 45px;
    font-size: 17px;
  }
}
