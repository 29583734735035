@use 'src/styles/style' as *;

.refreshEnergyImage {
  position: absolute;
  top: 0;
  left: -50px;
  width: 150px;
  z-index: 2;
  padding-top: 25px;

  &:active {
    animation: shake 0.3s ease-in-out;
  }
}

.marker {
  width: 30px;
  height: 30px;
  right: 0;
  top: 25px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-3px);
  }

  50% {
    transform: translateX(3px);
  }

  75% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}