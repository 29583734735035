@use '/src/styles/style.scss' as *;

.friendsList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid $color-white;
  background: linear-gradient(180deg, rgba(231, 190, 175, 0.25) 0%, rgba(253, 240, 226, 0.25) 100%);
  border-radius: 50px;
  text-align: center;
  color: $color-white;
  position: relative;

  &:after {
    content: '';
    box-shadow:
      inset 0 -3px 3px 0 rgba(155, 141, 227, 1),
      inset 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    border-radius: 50px;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    pointer-events: none;
  }
}

.label {
  font-size: 18px;
  font-weight: $fw-bold;
  align-self: center;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid $color-white;
  border-top: none;
  border-bottom: none;
  background: $color-black-gradient;
  border-radius: 50px;
}

.button {
  display: block;
  font-size: 13px;
  line-height: 13px;
  font-weight: $fw-bold;
  align-self: center;
  padding: 0 5px;
  color: $color-black-light;
  white-space: nowrap;

  &:not(:last-child) {
    border-right: 3px solid $color-black-light;
  }
}

.active {
  color: $color-white;
}

@media (max-width: 370px) {
  .label {
    font-size: 15px;
  }
  .button {
    font-size: 12px;
  }
}
