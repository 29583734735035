@use 'src/styles/style' as *;

.clearSlotButton {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  transform: translateY(-50%);
  position: absolute;
  right: 27%;
  bottom: -5px;
  z-index: 1;

  img {
    width: 105%;
  }
}

.clearSlotButton:after {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.75);
  color: $color-white;
  padding: 1px 4px;
  text-align: center;
  font-size: 12px;

  position: absolute;
  top: -15px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}

.clearState > img {
  filter: grayscale(100%);
}
