@use '/src/styles/style.scss' as *;

.welcomeBackModalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 10px 0;
  text-align: center;
  font-weight: $fw-extrabold;
  font-size: 20px;
}

.notificationContent {
  flex-grow: 1;
  padding-bottom: 15px;
  width: 100%;
  max-height: 50vh;

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile-mobileMedium {
    margin-top: 0;
  }
}
.employeesNotification {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.profitWrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  @include mobile-mobileMedium {
    gap: 3px;
  }
}
.profitCount {
  font-weight: $fw-bold;
  font-size: 26px;

  @include mobile-mobileMedium {
    font-size: 17px;
  }
}
.coinIcon {
  width: 25px;
  height: 25px;

  @include mobile-mobileMedium {
    width: 17px;
    height: 17px;
  }
}
