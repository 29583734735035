@use 'src/styles/style' as *;

.upgradeIcon {
  width: 27px;
  height: 27px;
  position: absolute;
  top: -7px;
  right: -7px;

  @include mobile-mobileMedium {
    width: 30px;
    height: 30px;
    top: -9px;
    right: -9px;
  }
}

// card body
.description {
  font-size: 10px;
  padding: 2px 0;
  text-align: center;
}

.countWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.userData {
  font-size: 16px;
  color: $color-white;
  font-weight: $fw-bold;

  @include mobile-mobileMedium {
    font-size: 14px;
  }
}
.profit {
  font-size: 16px;
  color: $color-green;
  font-weight: $fw-bold;
}

// card footer
.cardFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 26px;
}
.level {
  font-size: 10px;
  line-height: 15.1px;
  color: $color-black-lighter;
  font-weight: $fw-bold;
  display: flex;
  gap: 3px;
  position: absolute;
  left: 10px;
}

.footerTextWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.text {
  font-size: 15px;
  color: $color-black;
  font-weight: $fw-bold;
}

.textRed {
  color: $color-red;
}

.textGold {
  color: $color-orange-yellow;
}

.tutor {
  border-radius: 10px;
  box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.5);
  position: relative;
  z-index: 15;
  animation: pulse-scale 2s infinite;
}

@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
