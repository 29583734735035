@use 'src/styles/style' as *;

.cardsWrapper {
  width: 100%;
  border-radius: 5px;
  padding: 1px;
  min-height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $color-white;
  position: relative;

  box-shadow:
    inset 0 2px 3px 0 #f36c3f,
    0 4px 5px 0 rgba(0, 0, 0, 0.3),
    inset 0 -5px 8px 0 rgba(80, 73, 116, 1);
  background: $color-black-gradient;
}

// rating
.rating {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgba(80, 73, 116, 1);
  box-shadow: 0 0 3px 2px rgba(255, 255, 255, .9);
  font-weight: $fw-bold;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ratingBorder {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background: #A407FF;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -8px;
  top: -8px;
}

.top_1 {
  background: linear-gradient(180deg, #ffcc00 0%, #ffab1a 100%);
  box-shadow: 0 0 3px 2px rgba(255, 255, 255, 1);
}
.top_2 {
  background: linear-gradient(180deg, #68c0ff 0%, #53678d 100%);
  box-shadow: 0 0 3px 1px rgba(255, 255, 255, 1);
}
.top_3 {
  background: linear-gradient(180deg, #ffab1a 0%, #996710 100%);
  box-shadow: 0 0 3px 2px rgba(255, 255, 255, 1);
}

.top_1_border {
  background: linear-gradient(180deg, #ffcc00 0%, #ffab1a 100%);
}
.top_2_border {
  background: linear-gradient(180deg, #68c0ff 0%, #53678d 100%);
}
.top_3_border {
  background: linear-gradient(180deg, #ffab1a 0%, #996710 100%);
}
