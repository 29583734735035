@use 'src/styles/style' as *;

.button {
  margin: 0 auto;
  width: fit-content;

  background: linear-gradient(135deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid $color-white;
  border-radius: 50px;
}

.friendInfoWrapper {
  display: flex;
  align-items: center;

  padding: 10px 20px 10px 10px;
  border-radius: 30px;
  background-color: rgba(164, 171, 175, 0.5);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  @include mobile-mobileMedium {
    padding: 7px 15px 7px 7px;
  }
}

.textInfo {
  text-align: left;
  margin-left: 10px;
  font-size: 12px;
  font-weight: $fw-bold;
}
