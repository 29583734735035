@use '/src/styles/style.scss' as *;

.menu {
  border-radius: 10px;
  background: $color-black-gradient;
  display: flex;
  justify-content: space-between;
  padding: 13px 10px;
  width: 100%;
  position: relative;

  display: grid;
  grid-template-columns: repeat(4, 1fr);

  border: 2px solid #000;
  box-shadow:
    inset 0 -5px 10px 0 rgba(80, 73, 116, 1),
    inset 0 3px 7px 0 rgba(243, 108, 63, 1);
  @include mobile-mobileMedium {
    padding: 10px 5px;
  }

  &:after {
    content: '';
    background-image: url('../../assets/icons/menu_shadow.webp');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;

    position: absolute;
    width: 100%;
    height: 50px;
    top: -25px;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &:not(:last-child) {
    border-right: 3px solid $color-orange;
  }
}

.button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $color-white;
}

.icon {
  width: 30px;
  height: 30px;
}

.button span {
  display: block;
  font-weight: $fw-bold;
  font-size: 12px;
  margin-top: 2px;
  white-space: nowrap;

  @include mobile-mobileMedium {
    font-size: 12px;
  }
}

.active {
  color: $color-orange;
}

.marker {
  width: 17px;
  height: 16px;
  position: absolute;
  user-select: none;
  top: -10px;
  right: 2px;
}
