@use '/src/styles/style.scss' as *;

.inviteFriends {
  width: 100%;
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(231, 190, 175, 0.5);
  box-shadow:
    2px 2px 8px 3px rgba(92, 109, 122, 0.3) inset,
    -8px -7px 7px -3px rgba(244, 248, 251, 0.4) inset;
  padding: 15px 0 10px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.label {
  text-align: center;
  font-size: 30px;
  font-weight: $fw-bold;
  line-height: 1;
  margin-bottom: 10px;
}

.subLabel {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: $fw-bold;
}

.linkWrapper {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  background-color: $color-white;
  box-shadow:
    1px 1px 3px 3px rgba(118, 128, 136, 0.33) inset,
    -9px -7px 2px -3px rgba(244, 248, 251, 0.6) inset;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
  position: relative;
}

.coinIcon {
  width: 24px;
  height: 24px;
  margin: 0 5px;
}

.copyIcon {
  width: 22px;
  height: 20px;
  margin-left: 5px;
}

.linkText {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $color-black-light;
  font-size: 16px;
  line-height: 15px;
  font-weight: $fw-semibold;
}

@media (max-width: 390px) {
  .linkText {
    max-width: 280px;
  }
  .label {
    font-size: 23px;
  }

  .subLabel {
    font-size: 15px;
  }
}

.tooltip {
  visibility: hidden;
  width: 130px;
  background-color: rgba(0, 0, 0, 0.75);
  color: $color-white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.showTooltip .tooltip {
  visibility: visible;
  opacity: 1;
}
