@use 'src/styles/style' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  // position: relative;
  padding: $common-container-padding;
}

.filters {
  width: 100%;
  margin: 20px 0 10px;
}

.cardsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
