// example
@mixin placeholder {
  font-size: 16px;
  font-weight: $fw-regular;
  color: $color-french-grey;
}

@mixin shineAnim {
  @keyframes shine {
    0% {
      left: -100%;
      transition-property: left;
    }
    #{($anim-speed / ($anim-duration + $anim-speed) * 100%)},
    100% {
      left: 100%;
      transition-property: left;
    }
  }

  position: relative;

  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--shine-effect);
    animation: shine #{$anim-duration}s ease-in-out infinite;
  }
}
