.wrapper {
  width: 100%;
  gap: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textWrapper {
  flex-grow: 1;
}
