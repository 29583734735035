@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  padding: 5px;
}

// user name info
.userNameWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
  padding: 10px 0;
  border-bottom: 2px solid rgba(127, 119, 165, 1);
}

.userName {
  font-weight: $fw-bold;
}

// user skills
.userSkills {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-weight: $fw-bold;
  border-bottom: 2px solid rgba(127, 119, 165, 1);
}

.skill {
  width: 50%;
  padding: 5px 0;
  font-size: 15px;

  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.skillTitle {
  color: rgba(127, 119, 165, 1);
}

.power {
  background: linear-gradient(90deg, rgba(255, 133, 65, 1) 0%, rgba(255, 110, 43, 1) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.income {
  color: $color-orange-yellow;
}

// user info
.infoContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 5px;
  text-align: start;
  margin: 10px 0;
}

.infoCell {
  font-weight: $fw-bold;
  margin-bottom: 5px;
}

.title {
  font-size: 12px;
  color: rgba(127, 119, 165, 1);
  line-height: 1;
  white-space: nowrap;

  display: flex;
  align-items: center;
}

.value {
  color: $color-white;
  margin-left: 3px;

  display: inline-flex;
  align-items: center;
  gap: 3px;
}

.hired {
  border: 1px solid #7f77a5;
  border-radius: 5px;
  width: 100%;
  height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: $fw-bold;
  color: #7f77a5;

  display: flex;
  align-items: center;
  justify-content: center;
}