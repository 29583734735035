@use '/src/styles/style.scss' as *;

.modalOverflow {
  width: 100%;
  max-width: 420px;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
  align-items: center;
}
.modalWrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
}
.modal {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  max-height: 95vh;
  margin: 0 10px;
  border-radius: 10px;
  padding: 15px 10px;
  background: #504974;
  color: $color-white;
}

.modalIsOpened {
  transition: all 0.5s ease;
  animation: overlayIn 0.5s ease forwards;
}
.modalOnClose {
  transition: all 0.5s ease;
  animation: overlayOut 0.5s ease forwards;
}

.contentVisible {
  transition: all 0.4s ease;
  animation: scrollIn 0.4s ease forwards;
}
.contentHidden {
  transition: all 0.4s ease;
  animation: scrollOut 0.4s ease forwards;
}

@keyframes scrollIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scrollOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes overlayIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
    display: flex;
  }
}

@keyframes overlayOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
