@use '/src/styles/style.scss' as *;

.avatar {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-position: center top;
  background-size: 100% 100%;
  border: 1px solid $color-white;
}
.userAvatar {
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
}

.noActive {
  opacity: 0.4;
  filter: grayscale(100%);
}

.Employees {
  width: 45px;
  height: 45px;
}
.User {
  width: 70px;
  height: 70px;
}
.Owner {
  width: 15px;
  height: 15px;
  border: 1px solid $color-white;
}
.Office {
  width: 25px;
  height: 25px;
  border: 1px solid $color-white;
}

.Square {
  width: 70px;
  height: 70px;
  border-radius: 5px;
}

@include mobile-mobileMedium {
  .Employees {
    width: 40px;
    height: 40px;
  }
  .User {
    width: 55px;
    height: 55px;
  }

  .Owner {
    width: 17px;
    height: 17px;
  }
}
