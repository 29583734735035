@use 'src/styles/style' as *;

.appWrapper {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  // height: 100svh;
  flex-grow: 1;
  overflow: hidden;
  padding-top: 20px;

  background-position: top;
  background-repeat: no-repeat;
  background-color: $color-body-bg;
  background-size: 100%;
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pages {
  width: 100%;
  // overflow-y: auto;
  flex-grow: 1;
  display: flex;
  // padding-bottom: 300px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
}
