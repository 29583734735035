@use 'src/styles/style' as *;

.upgradeCardButton {
  transition: all 0.4s ease;
  height: 100%;
  &:active {
    transform: scale(0.9);
  }
}
.updatedCardsWrapper {
  width: 100%;
  border-radius: 10px;
  position: relative;
  box-shadow:
    inset 0px -5px 8px 0 rgba(80, 73, 116, 1),
    0px 5px 5px 0 rgba(0, 0, 0, 0.25);

  background: $color-black-gradient;
  color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.header {
  text-align: center;
  border-radius: 8px 8px 0 0;
  border-bottom: none;
  box-shadow: inset 0 8px 5px 0 rgba(255, 153, 0, 0.3);
  background: linear-gradient(180deg, #da5e29 0%, #a74c19 100%);
  width: 100%;

  padding: 5px 0;
  font-size: 15px;
  color: $color-white;
  font-weight: $fw-bold;
}
.cardBody {
  border: 3px solid #777777;
  border-radius: 0 0 10px 10px;
  border-top: none;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.main,
.footer {
  width: 100%;
  padding: 0 10px;

  display: flex;
  justify-content: center;
}
.divider {
  margin: 3px 0;
  width: 90%;
}

.main {
  flex-grow: 1;
}
