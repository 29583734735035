@use '/src/styles/style.scss' as *;

.scoreWrapper {
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 5px;
  position: relative;
  z-index: 1;
  border-radius: 50px;

  background: linear-gradient(135deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.coinScore {
  @include mobile-mobileMedium {
    font-size: 30px;
  }
}

.iconWrapper {
  width: 40px;
  height: 40px;

  @include mobile-mobileMedium {
    width: 30px;
    height: 30px;
  }
}
