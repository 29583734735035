@use 'src/styles/style' as *;

.slotWrapper {
  position: relative;
  width: 100%;
  max-height: 121px;
  z-index: 11;
  pointer-events: all;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.tutor {
  border-radius: 100%;
  box-sizing: content-box;
  padding: 20px 0 20px 5px;
  box-shadow:
    0 0 20px 20px rgba(255, 255, 255, 0.5),
    inset 0 0 10px 100px rgba(255, 255, 255, 0.5);
  animation: pulse-scale 2s infinite;
  border: 0.5px solid rgba(255, 255, 255, 0.58);
}

@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
