@use '../../../styles/style' as *;

.wrapper {
  width: 160px;
  min-height: 110px;
  height: 100%;

  display: flex;
  flex-direction: column;

  border-radius: 10px;
  background-image: url('../../../assets/images/invitePlayerCardBg.webp');
  background-size: 100% 100%;
  position: relative;
  justify-content: flex-end;
  align-items: center;

  span {
    text-align: center;
    line-height: 1;
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
  }
}

.avatar {
  width: 70px;
  height: 70px;
  padding: 3px;
  margin-top: 3px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  >div {
    width: 63px;
    height: 63px;
  }
}

.avatarComp {
  border: none;
}

.plus {
  position: absolute;
  left: 50%;
  top: 3px;
  transform: translateX(-50%);
}

.noFriend {
  margin-bottom: 10px;
  color: #fff;
}