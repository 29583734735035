.wrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonWrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 10px;
}
