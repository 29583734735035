.officeWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-grow: 1;
  position: relative;
  pointer-events: none;
}

.workPlace {
  width: 100%;
  max-width: 100%;
  max-height: 350px;
  margin: 20px 0 0 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.hidden {
    display: none;
  }
}

// Стили для расположения столов и размеров
.slots_1 {
  max-width: 47%;
}
.slots_2 {
  max-width: 38%;
  margin-bottom: 20px;
}
.slots_3 {
  max-width: 40%;
  max-height: 400px;
}
.slots_4 {
  max-width: 40%;
  max-height: 400px;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.slots_5 {
  max-width: 40%;
  max-height: 400px;
}
.slots_6 {
  max-width: 30%;
  justify-content: flex-end;
  margin-bottom: 20px;
  max-height: 400px;
}
.slots_7 {
  max-width: 30%;
  justify-content: flex-end;
  margin-bottom: 20px;
  max-height: 400px;
}
.slots_8 {
  max-width: 30%;
  justify-content: flex-end;
  margin-bottom: 20px;
  max-height: 400px;
}
.slots_9 {
  max-width: 27%;
  justify-content: flex-end;
  margin-bottom: 20px;
  max-height: 400px;
}
.slots_10 {
  max-width: 27%;
  justify-content: flex-end;
  margin-bottom: 20px;
  max-height: 400px;
}

.tutor {
  border-radius: 100%;
  box-sizing: content-box;
  padding: 20px 0 20px 5px;
  box-shadow:
    0 0 20px 20px rgba(255, 255, 255, 0.5),
    inset 0 0 10px 100px rgba(255, 255, 255, 0.5);
  animation: pulse-scale 2s infinite;
  border: 0.5px solid rgba(255, 255, 255, 0.58);
}
