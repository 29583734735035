@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.buttonWrapper {
  width: 80%;
  height: 100%;
}

// кнопка
.button {
  width: 100%;
  max-width: 400px;
  position: relative;
  transition: 0.5s linear;
  &:active {
    transform: scale(0.9);
  }
}

.buttonRing {
  padding: 10px;
  max-width: 300px;
  min-width: 100%;
  width: 100%;
  background-color: rgba(157, 151, 151, 0.98);
  border-radius: 75px;
  box-shadow:
    5px 7px 4px -5px rgba(73, 73, 73, 0.59) inset,
    -7px -9px 3px -5px rgba(203, 203, 203, 0.6) inset;
  border: 0.5px solid #e4f0f2;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tapButton {
  height: 110px;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgb(0, 200, 83) 0%,
    rgb(7, 208, 75) 25%,
    rgb(21, 216, 64) 50%,
    rgb(35, 223, 51) 75%,
    rgb(48, 231, 31) 100%
  );
  border: 0.5px solid #e4f0f2;
  border-radius: 75px;
  box-shadow:
    -3px -5px 27px -2px rgba(49, 46, 46, 0.6) inset,
    -11px -14px 9px -5px rgba(247, 242, 242, 0.62);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include shineAnim;
}

.coins {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff29;

  font-weight: bold;
  font-size: 25px;
}
.icon {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: $fw-bold;
  color: $color-white;
  line-height: 1;
}

.marker {
  width: 40px;
  height: 40px;
  top: -8px;
  right: 3px;
}
