@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.buttonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.button {
  font-weight: $fw-regular;
  height: 55px;

  @include mobile-mobileMedium {
    height: 45px;
  }
}

.hireButtonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;

  @include mobile-mobileMedium {
    font-size: 12px;
  }
}
.priceWrapper {
  display: flex;
  align-items: center;
}
.iconWrapper {
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}
.textGold {
  font-weight: $fw-bold;
  color: $color-orange-yellow;
}

.textRed {
  font-weight: $fw-bold;
  color: $color-red;
}
