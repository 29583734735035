@use 'src/styles/style' as *;

.buttonPanel {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.coinsRef {
  width: 300px;
  position: absolute;
}
