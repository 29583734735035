$desktop-width: 420px;
$mobile-width: 420px;
$mobile-medium-width: 350px;
$mobile-mini-width: 320px;

@mixin mobile-mobileMini {
  @media (min-width: #{$mobile-width}) and (max-width: #{$mobile-mini-width}) {
    @content;
  }
}

@mixin mobile-mobileMedium {
  @media (min-width: #{$mobile-mini-width}) and (max-width: #{$mobile-medium-width}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) {
    @content;
  }
}
