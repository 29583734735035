@use 'src/styles/style' as *;

.ownerWrapper {
  height: 24px;
  width: 100%;
  color: $color-white;
  font-weight: $fw-semibold;
  font-size: 10px;
  padding: 0 5px;
  text-align: center;
  border-radius: 5px;

  background-color: $color-blue-black;
  box-shadow:
    inset 0 -6px 5px 0 rgba(255, 255, 255, 0.2),
    inset 0 5px 5px 0 rgba(255, 255, 255, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}
.small {
  border-radius: 0 0 5px 5px;
  background: linear-gradient(0deg, #db6429, #db6429), linear-gradient(180deg, #da5e29 0%, #a74c19 100%);
  box-shadow: inset 0 -6px 5px 0 rgba(0, 0, 0, 0.2);
}
.ownerInfoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ownerText {
  color: $color-black-gray;
  font-weight: $fw-bold;
}
.ownerAvatar {
  margin: 0 5px;
}
