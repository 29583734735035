@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.buttonWrapper {
  width: 80%;
  height: 100%;
}

// кнопка
.button {
  width: 100%;
  max-width: 400px;
  position: relative;
  transition: 0.5s linear;
  position: relative;
  &:active {
    transform: scale(0.9);
  }
}

.buttonRing {
  padding: 10px;
  max-width: 300px;
  min-width: 100%;
  width: 100%;
  background-color: rgba(157, 151, 151, 0.98);
  border-radius: 75px;
  box-shadow:
    5px 7px 4px -5px rgba(73, 73, 73, 0.59) inset,
    -7px -9px 3px -5px rgba(203, 203, 203, 0.6) inset;
  border: 0.5px solid #e4f0f2;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tapButton {
  height: 110px;
  width: 100%;
  background: linear-gradient(180deg, rgb(0, 247, 177) 0%, rgb(0, 163, 238) 50%, rgb(211, 52, 208) 100%);
  border: 0.5px solid #e4f0f2;
  border-radius: 75px;
  box-shadow:
    -3px -5px 27px -2px rgba(49, 46, 46, 0.6) inset,
    -11px -14px 9px -5px rgba(247, 242, 242, 0.62);

  display: flex;
  align-items: center;
  justify-content: center;
  @include shineAnim;
}

.icon {
  width: 30px;
  height: 30px;
}

.text {
  margin-left: 10px;
  font-size: 28px;
  font-weight: $fw-bold;
  color: $color-white;
}

.marker {
  width: 40px;
  height: 40px;
  z-index: 1;
}
