@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.addUserButton {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.65);
}
.buttonContentWrapper {
  display: flex;
  align-items: center;
}
.addUserIcon {
  margin-left: 5px;
  width: 17px;
  height: 17px;
}
