@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: $common-container-padding;
}

.cardWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}
