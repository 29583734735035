@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.energyLimit {
  position: absolute;
  right: 0;
  top: -40px;
}

.buttonWrapper {
  width: 80%;
  height: 100%;
}

// кнопка
.button {
  width: 100%;
  max-width: 400px;
  position: relative;
}

.buttonRing {
  padding: 10px;
  max-width: 300px;
  min-width: 100%;
  width: 100%;
  background-color: rgba(157, 151, 151, 0.98);
  border-radius: 75px;
  box-shadow:
    5px 7px 4px -5px rgba(73, 73, 73, 0.59) inset,
    -7px -9px 3px -5px rgba(203, 203, 203, 0.6) inset;
  border: 0.5px solid #e4f0f2;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tapButton {
  height: 110px;
  width: 100%;
  background: linear-gradient(to top, #ec901b, #f8d563);
  border: 0.5px solid #e4f0f2;
  border-radius: 75px;
  box-shadow:
    -3px -5px 27px -2px rgba(49, 46, 46, 0.6) inset,
    -11px -14px 9px -5px rgba(247, 242, 242, 0.62);

  display: flex;
  align-items: center;
  justify-content: center;
}

.coin > img {
  position: relative;
  max-width: 85px;
}

.button.active .tapButton {
  transition: 0.3s ease;
  background: linear-gradient(to top, #ec721b, #f8ce63);
  box-shadow: 0 0 25px 5px rgba(72, 30, 30, 0.66) inset;
}
.button.notEnough:active .tapButton {
  background: radial-gradient(circle, #fff, #e8771b, #ff0000);
}

.button.active img {
  transition: 0.3s ease;
  transform: scale(1.1);
}

// коин
.click {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  animation: moveUp 1.5s ease-out forwards;
}

@keyframes moveUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: translateY(-200px);
  }
}

.tapCoin {
  width: 45px;
  height: 45px;

  font-size: 14px;
  font-weight: $fw-bold;
  color: $color-black;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('../../../assets/icons/empyCoin.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  transition: opacity 1s ease-out;
}

.noEnergy {
  font-size: 16px;
  font-weight: $fw-bold;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  white-space: nowrap;
  color: #805716;
}

.marker {
  width: 20px;
  height: 20px;
  top: -7px;
  right: -7px;
  z-index: 3;
}
