@use 'src/styles/style' as *;

.staffInfo {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.75);
  color: $color-white;
  padding: 5px;
  text-align: center;
  font-size: 13px;
  min-width: 50px;

  position: absolute;
  top: -8px;
  left: 40%;
  transform: translateX(-50%);
  z-index: 1;

  &.disabled {
    background-color: rgba(119, 119, 119, 0.75);
  }
}

.infoButton {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: -20px auto 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  font-size: 10px;
  color: $color-black;
}

.clock {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);

  white-space: nowrap;
  padding: 2px 5px;
  border-radius: 6px;
  background-color: #ffc802;
  font-size: 8px;
  font-weight: bold;
  color: $color-black;
}
.staffInfoWrapper {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.icon {
  width: 11px;
  height: 11px;
  margin-right: 2px;
}

.profit {
  font-weight: bold;
  color: #ffb800;
}

.power {
  font-weight: bold;
  background: linear-gradient(90deg, rgba(255, 133, 65, 1) 0%, rgba(255, 110, 43, 1) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.visible {
  visibility: visible;
  opacity: 1;
  display: block;
}

.marker {
  width: 20px;
  height: 20px;
  top: -10px;
  right: -10px;
}
