.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.7s ease;
  z-index: 100;

  background-image: url(../../assets/images/load.webp);
  background-size: 100% 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    width: 100px;
    height: 100px;
    animation: loop 1s ease infinite;
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

@keyframes loop {
  to {
    transform: rotate(360deg);
  }
}
