@use 'src/styles/style' as *;

.titleWrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.bossPageHeaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.settingsIcon,
.eyeIcon {
  width: 50px;
  height: 50px;
  padding: 7px 4px 3px 4px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1;

  border: 1px solid $color-white;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile-mobileMedium {
    width: 40px;
    height: 40px;
    top: 15px;
  }
}
.settingsIcon {
  padding: 0;
  width: 45px;
  height: 45px;
  border: none;
  background: transparent;
  box-shadow: 0 0 4px 2px rgba(255,255,255,.5);
  animation: rotateSettings 20s linear infinite;
  transition: 1s;
}
.eyeIcon {
  top: 23px;
  right: 20px;
}

@keyframes rotateSettings {
  100% {
    transform: rotate(360deg) ;
  }
}

