@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  padding: $common-container-padding;
  padding-top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  width: 100%;
  font-size: 25px;
  font-weight: $fw-bold;
  padding: 10px 10px;
  margin: 0 auto;
}

.tabsWrapper {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.wrapperSettings {
  height: 55px;
  width: 100%;
  padding: 5px 5px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @include mobile-mobileMedium {
    font-size: 14px;
  }
}
.tabWrapper {
  display: flex;
  padding-top: 3px;
  align-items: center;
}
.deleteAccIcon,
.bellIcon {
  width: 35px;
  height: 35px;
  margin-right: 10px;

  @include mobile-mobileMedium {
    width: 30px;
    height: 30px;
  }
}
.bellIcon {
  width: 30px;
  height: 30px;

  @include mobile-mobileMedium {
    width: 25px;
    height: 25px;
  }
}
.tabIcon {
  width: 50px;
  height: 50px;
  padding-top: 3px;

  @include mobile-mobileMedium {
    width: 45px;
    height: 45px;
  }
}

.changed {
  text-align: left;
  color: #11c800;
}
