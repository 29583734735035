@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: $common-container-padding;
  height: 100%;
}

.contentWrapper {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.title {
  color: $color-white;
  font-weight: $fw-bold;
  text-align: center;
  position: relative;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.cards {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.titleTop {
  font-size: 26px;
  text-align: center;
  color: $color-black-main;
  font-weight: 600;
  line-height: 1;
  position: relative;

  &::before {
    width: 100%;
    height: 80%;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -2;
    background: radial-gradient(50% 50% at 50% 50%, #F4C601 0%, rgba(204, 124, 83, 0) 100%);
    opacity: 0.7;
  }
}

.titleBottom {
  width: 300px;
  font-size: 20px;
  text-align: center;
  margin: auto;
  color: $color-black-main;
  font-weight: 600;
}