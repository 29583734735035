@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
  max-width: 420px;
  bottom: 0;
}

.staffBg {
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 6px 5px 0 rgba(255, 153, 0, 0.35);
  background: $color-black-gradient;
}

.tabMenuWrapper {
  width: 100%;
  padding: 0 5px 5px 5px;
}
.tabs {
  display: flex;
  background: #367cde;
  box-shadow:
    inset 0 5px 5px 0 rgba(255, 153, 0, 0.35),
    inset 0 -5px 10px 0 rgba(59, 166, 157, 0.75),
    10px 10px 20px 0 rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  border-radius: 0 0 10px 10px;
  margin: 0 10px;
  overflow: hidden;
  height: 50px;
  margin-bottom: 10px;

  .tab {
    flex-grow: 1;
    line-height: 1;
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 20px;

    &:not(:last-child) {
      border-right: 2px solid #fff;
    }

    &.active {
      background-color: #38c1ff;
    }
  }
  .tab > div {
    max-width: 80px;
  }
}
