@use 'src/styles/style' as *;

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 10px;
  width: 100%;
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

// info
.info {
  font-weight: $fw-bold;
  font-size: 14px;
}

.userName {
  text-align: left;
}

// power
.powerWrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}
.power {
  background: linear-gradient(90deg, rgba(255, 133, 65, 1) 0%, rgba(255, 110, 43, 1) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// income
.incomeWrapper {
  display: flex;
  align-items: center;
  gap: 3px;

  font-weight: $fw-bold;
}
.income {
  color: $color-orange-yellow;
}
.resell {
  color: #fff;
  font-size: 18px;
}

.button {
  width: 100px;
  height: 50px;
  font-size: 16px;
  font-weight: $fw-medium;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile-mobileMedium {
    width: 80px;
    height: 40px;
    font-size: 12px;
  }
}

.blue {
  color: $color-white;
  background: #037bc8;
  box-shadow:
    inset 0 5px 5px 0 rgba(255, 153, 0, 0.35),
    inset 0 -5px 10px 0 rgba(59, 166, 157, 0.75),
    10px 10px 20px 0 rgba(0, 0, 0, 0.3);
}
.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #1da1f5;
  background: transparent;
}
.hireButtonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.priceWrapper {
  display: flex;
  align-items: center;
}
