@use 'src/styles/style' as *;

.tutorOverlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);

  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  align-items: flex-end;
}

.tutor {
  width: 100%;
  padding: 20px 5px 5px;
}
.tutorImage {
  max-width: 100px;
  width: 100%;
  margin: 0 0 -10px 15px;

  /* Начальные условия для анимации */
  transform: translateX(-100%);
  opacity: 0;
}

.tutorImageView {
  animation: slideInFromRight 0.5s ease-out 0.5s forwards;
}

@keyframes slideInFromRight {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.tutorText {
  color: $color-black;
  background-color: $color-white;
  border-radius: 10px;
  padding: 20px 10px;
  min-height: 110px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-weight: $fw-bold;

  position: relative;
  z-index: 1;
  box-shadow:
    0 10px 10px 5px rgba(0, 0, 0, 0.2),
    10px 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.step {
  margin: auto;
  box-sizing: content-box;
  border-radius: 50%;
  box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.5);
  width: 100px;
  height: 100px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse-scale 2s infinite;
}

.stepHidden {
  display: none;
}

.tutorDisable {
  display: none;
}

@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
