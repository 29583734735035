@use 'src/styles/style' as *;

.wrapper {
  width: 100%;
}
.buttonWrapper {
  width: 100%;
}
.buttonText {
  margin-right: 5px;
  font-size: 24px;
  font-weight: $fw-medium;
}
.contentWrapper {
  display: flex;
  align-items: center;
}
.refrigeratorImage {
  position: relative;
  width: 100px;
  min-height: 169px;
  margin: -40px 0 -15px 20px;
  animation: refrigeratorAnimationUp 1.5s ease;

  &:after {
    content: '';
    border-radius: 10px;
    position: absolute;
    left: 35px;
    right: 3px;
    top: 30px;
    bottom: 40px;
    animation: refrigeratorAnimationGlow 2s ease-in-out infinite;
  }
}

@keyframes refrigeratorAnimationUp {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes refrigeratorAnimationGlow {
  0% {
    box-shadow:
      inset #48abe0 0 0 20px,
      #48abe0 0 0 10px;
  }
  50% {
    box-shadow:
      inset blueviolet 0 0 20px,
      blueviolet 0 0 10px;
  }
  75% {
    box-shadow:
      inset rebeccapurple 0 0 20px,
      rebeccapurple 0 0 10px;
  }
  100% {
    box-shadow:
      inset #48abe0 0 0 20px,
      #48abe0 0 0 10px;
  }
}

.content {
  flex-grow: 1;
}

.contentTitle {
  font-size: 25px;
  line-height: 20px;
  font-weight: $fw-bold;
  color: $color-yellow;
  text-align: center;
  margin-bottom: 5px;
}
.energy {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  transform: scale(1.2);
}
.timerButton {
  display: flex;
  align-items: center;
  gap: 3px;

  font-weight: 600;
  background: #43736c;
}
