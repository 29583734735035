@use 'src/styles/style' as *;

.bossCard {
  background: rgb(53, 51, 65);
  background: linear-gradient(180deg, #353341 0%, #292830 100%);
  box-shadow: inset 0 8px 5px 0 rgba(80, 73, 116, 0.5);
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  color: $color-white;
}
.commonInfoWrapper {
  display: flex;
  align-items: center;
  padding: 10px;
}

.infoWrapper {
  padding-left: 10px;
  width: 100%;
}
.name,
.rank {
  padding: 5px 0;
  font-size: 15px;
  font-weight: $fw-bold;

  @include mobile-mobileMedium {
    font-size: 11px;
  }
}

.powerAndCountWrapper {
  display: flex;
  align-items: start;
  gap: 10px;
}

.power,
.countWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 35px;
  padding: 0 10px;
  width: 100%;

  box-shadow:
    inset 0 -2px 2px 0 rgba(155, 141, 227, 1),
    inset 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid $color-white;
  border-radius: 80px;

  font-size: 11px;
  font-weight: $fw-bold;
  line-height: 1;

  @include mobile-mobileMedium {
    font-size: 10px;
  }
}

.powerIcon {
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  margin: 0 3px;

  @include mobile-mobileMedium {
    width: 14px;
    height: 14px;
  }
}
.powerCounter {
  font-size: 14px;

  background: linear-gradient(90deg, rgba(255, 133, 65, 1) 0%, rgba(255, 110, 43, 1) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include mobile-mobileMedium {
    font-size: 14px;
  }
}

.coinImage {
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  margin: 0 3px;

  @include mobile-mobileMedium {
    width: 14px;
    height: 14px;
  }
}

.ownerWrapper {
  height: 30px;
  color: $color-white;
  font-weight: $fw-semibold;
  font-size: 15px;

  text-align: center;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px -29px 18px -30px rgba(255, 255, 255, 0.86) inset;
  background: $color-blue-black; //$color-blue;

  display: flex;
  justify-content: center;
  align-items: center;
}
.ownerAvatar {
  margin: 0 5px;
}
